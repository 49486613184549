import { FunctionComponent } from "react";
import Link from "next/link";
import Button from "../Button/Button";

const Navbar: FunctionComponent = () => {
  return (
    <nav className="w-full py-4 px-2 md:px-4 flex-grow-0">
      <div className="w-full max-w-6xl h-full mx-auto flex justify-between items-center">
        <Link href="/">
          <a className="flex items-center justify-center">
            <img
              src="/logo.png"
              className="h-10 w-10 rounded-full mr-3"
            />
            <p>Onlinevertrag.app</p>
          </a>
        </Link>
        <div className="items-center ">
          <Link href="/signin">
            <Button
              href="/signin"
              variant="outline"
              size="small"
              className="sm:mr-4"
              elementType="a"
            >
              Anmelden
            </Button>
          </Link>
          <div className="hidden sm:inline-block">
            <Link href="/signup">
              <Button
                variant="primary"
                size="small"
                elementType="a"
                href="/signup"
              >
                Registrieren
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
