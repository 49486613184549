// TODO: Es wurde auf fp-ts/Either gewechselt. Funktion hasTag und die Dokumentation sind veraltet.

function hasTag<TObj, TValue>(obj: TObj): obj is TObj & { tag: TValue } {
    return obj && typeof obj === "object" && 'tag' in obj;
}

function buildMessage(value: unknown): string {
    return hasTag(value)
        ? `Reached tag: ${value.tag + ''}`
        : `Reached unexpected value: ${JSON.stringify(value)}`;
}

/**
 * Gives you a nice runtime error while still having an exhaustive check at compile time in your switch.
 * ```typescript
 * function handleResult(Result<string, int> result) : void {
 *    switch (result.tag) {
 *      case OkTag.name: console.log(`Success: ${result.value}`); return;
 *      case ErrTag.name: console.log(`ErrorCode: ${result.value}`); return;
 *      default: throw new UnreachableVariantError(result);
 *    }
 * }
 * ```
 * If you add another variant to the `Result` type or forgot one of them, then you will get a compile time error.
 * If the type is not correctly declared you get a nice stacktrace with the unhandled tag at runtime.
 * It also enables you to omit the return type.
 * 
 * If you have a generic union you need to pass the tag to the {@link UnreachableVariantError}.
 * ```typescript
 * function handleResult<TOk, TErr>(Result<TOk, TErr> result) : void {
 *    switch (result.tag) {
 *      case OkTag.name: console.log(`Success: ${result.value}`); return;
 *      case ErrTag.name: console.log(`ErrorCode: ${result.value}`); return;
 *      default: throw new UnreachableVariantError(result.tag);
 *    }
 * }
 * ```
 */
export class UnreachableVariantError extends Error {
    constructor(value: never) {
        super(buildMessage(value));
    }
}