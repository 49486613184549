import { HttpError } from "../../http-error";
import * as t from "io-ts";
import { BadResponseBodyError } from "../../bad-response-body-error";

export const BetaSignUpRequest = t.type({
  email: t.string,
});
export type BetaSignUpRequest = t.TypeOf<typeof BetaSignUpRequest>;

export const BetaSignUpResponse = t.type({
  success: t.boolean,
});
export type BetaSignUpResponse = t.TypeOf<typeof BetaSignUpResponse>;
export type BetaSignUpResponseOutput = t.OutputOf<typeof BetaSignUpResponse>;

export async function betaSignUp(email: string): Promise<BetaSignUpResponse> {
  const res = await fetch("/api/auth/betaTester", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(BetaSignUpRequest.encode({
      email,
    })),
  });
  if (res.status === 403) {
    throw new HttpError(403, "Diese E-Mail wird bereits verwendet.");
  }

  const success = BadResponseBodyError.throwIfLeft(res.status,
    BetaSignUpResponse.decode(await res.json()));
  return success;
}