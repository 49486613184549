import { FunctionComponent } from "react";
import Footer from "./Footer";

const Layout: FunctionComponent = ({ children }) => {
  return (
    <>
      <div className="full-width px-2 md:px-4">
        <div className="max-w-6xl mx-auto flex flex-col min-h-screen">
          {children}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Layout;
