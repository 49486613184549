import { isRecord } from "./utils/record";

export function getErrorMessage(error: unknown) {
  if (!(error instanceof Error))
    return null;

  if ("graphQLErrors" in error && error.graphQLErrors) {
    for (const graphQLError of Object.values(error.graphQLErrors)) {
      const extensions: unknown = isRecord(graphQLError) && graphQLError.extensions;
      const message = isRecord(graphQLError) && graphQLError.message;
      if (
        isRecord(extensions) &&
        extensions.code === "BAD_USER_INPUT" &&
        typeof message === "string"
      ) {
        return message;
      }
    }
  }
  return error.message;
}
