/** https://www.reddit.com/r/typescript/comments/ns6595/comment/h17maa4/?context=3
 */
export function isRecord(value: unknown): value is Record<string, unknown> {
  return !!value && typeof value === 'object' && !Array.isArray(value);
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
type NonRecord = string | null | bigint | symbol | undefined | number | boolean | Function | NonRecord[];

export function isNotRecord(value: unknown): value is NonRecord {
  return typeof value !== 'object' || Array.isArray(value);
}
