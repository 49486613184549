import { logError } from "./error-utils";
import PrettyPrintIoTsErrors from "./io-ts/PrettyPrintIoTsErrors";
import * as t from "io-ts";
import { Has } from "ts-toolbelt/out/Union/Has";

function buildMessage(value: unknown, topic?: string): string {
    return topic
        ? `Reached unexpected value for ${topic}: ${JSON.stringify(value)}`
        : `Reached unexpected value: ${JSON.stringify(value)}`;
}

export class UnexpectedValueError extends Error {
    static logAndFallback<A>(topic: string, decoded: t.Validation<A>): Has<A, undefined> extends 1 ? never : A | undefined
    static logAndFallback<A, F>(topic: string, decoded: t.Validation<A>, fallback: F): A | F
    static logAndFallback<A, F>(topic: string, decoded: t.Validation<A>, fallback?: F) {
        if (decoded._tag === "Left") {
            logError(`Unexpected value for ${topic}`, PrettyPrintIoTsErrors(decoded.left));
            return fallback;
        }

        return decoded.right;
    }

    static throwIfLeft<A>(topic: string, decoded: t.Validation<A>) {
        if (decoded._tag === "Left") {
            logError(`Unexpected value for ${topic}`, PrettyPrintIoTsErrors(decoded.left));
            throw new UnexpectedValueError(decoded.left);
        }

        return decoded.right;
    }

    static throwIfNot<A, O, I>(topic: string, codec: t.Type<A, O, I>, value: I) {
        if (!codec.is(value)) {
            logError(`Unexpected value for ${topic}`, `The given value has not the required type for ${codec.name}.`);
            throw new UnexpectedValueError(value);
        }

        return value;
    }

    static assert<A, O, I>(topic: string, codec: t.Type<A, O, I>, value: I): asserts value is I & A {
        if (!codec.is(value)) {
            logError(`Unexpected value for ${topic}`, `The given value has not the required type for ${codec.name}.`);
            throw new UnexpectedValueError(value);
        }
    }

    static throwIfMissing<T>(topic: string, value: T | undefined | null): T {
        if (value === undefined || value === null) {
            logError(`Unexpected value for ${topic}`, `The given value was null or undefined.`);
            throw new UnexpectedValueError(value);
        }

        return value;
    }

    constructor(value: unknown, topic?: string) {
        super(buildMessage(value, topic));
    }
}