import dayjs from "dayjs";
import { isString } from "fp-ts/lib/string";
import { Optional } from "ts-toolbelt/out/Object/Optional";
import { AnswerChoices } from "../../components/lib/form-data";
import { ComponentValidations } from "./component-validations";
import { ComponentId } from "../types/ids";
import { UnexpectedValueError } from "../unexpected-value-error";
import { UnreachableVariantError } from "../unreachable-variant-error";

type CheckIsTooYoungResult = "too-young" | "not-yet-born";

const checkIsTooYoung = (date: Date, age: number): CheckIsTooYoungResult | undefined => {
    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);
    const isAgeDate = new Date(
        today.getFullYear() - age,
        today.getMonth(),
        today.getDate()
    ).getTime();
    const input = date.getTime();
    if (input - today.getTime() >= 0)
        return "not-yet-born";

    if ((input - isAgeDate) > 0)
        return "too-young";

    return undefined;
}

const notYetBornText = "Das Datum liegt in der Zukunft";

const checkIsAge = (date: string, age: number): ValidateResult | null => {
    const checkDateResult = checkIsDate(date);
    if (isString(checkDateResult)) {
        return { type: "inline", text: checkDateResult };
    }

    const checkResult = checkIsTooYoung(checkDateResult, age);
    switch (checkResult) {
        case "not-yet-born":
            return { type: "inline", text: notYetBornText };

        case "too-young":
            return {
                type: "inline",
                text: `Du musst ${age} Jahre alt sein, um hier fortzufahren, oder komm mit deinen Eltern persönlich ins Studio.`
            };

        case undefined:
            return null;

        default:
            throw new UnreachableVariantError(checkResult);
    }
};

const checkUnder18Warning = (date: string): ValidateResult | null => {
    const checkDateResult = checkIsDate(date);
    if (isString(checkDateResult)) {
        return { type: "inline", text: checkDateResult };
    }

    const age = 18;
    const checkResult = checkIsTooYoung(checkDateResult, age);
    switch (checkResult) {
        case "not-yet-born":
            return { type: "inline", text: notYetBornText };

        case "too-young":
            return {
                type: "popup",
                text: "Der Vertragsnehmer ist unter 18, eine Unterschrift des Erziehungsberechtigen ist notwendig."
            };

        case undefined:
            return null;

        default:
            throw new UnreachableVariantError(checkResult);
    }
};

const checkIsDate = (date: string): Date | string => {
    if (date.indexOf('-') > 0) {
        // Bei input[type="date"] enthaelt .value ein Datum im ISO-Format

        const splitInput: string[] = date.split("-");
        const year = parseInt(splitInput[0] ?? "0");
        const month = parseInt(splitInput[1] ?? "1") - 1;
        const day = parseInt(splitInput[2] ?? "0");
        const parsedDate = new Date(year, month, day);
        if (parsedDate.toString() === "Invalid Date") {
            return "Datum ist ungültig";
        }
        const validDate = dayjs(parsedDate).format("YYYY-MM-DD") === date;
        if (!validDate) {
            return "Kein gültiges Datum";
        }
        return parsedDate;
    } else {
        // Bei input[type="text"] wird das deutsche Datumsformat erwartet

        const splitInput: string[] = date.split(".");
        const day = parseInt(splitInput[0] ?? "0");
        const month = parseInt(splitInput[1] ?? "1") - 1;
        const year = parseInt(splitInput[2] ?? "0");
        const parsedDate = new Date(year, month, day);
        if (parsedDate.toString() === "Invalid Date") {
            return "Datum muss in diesem Format angegeben werden: TT.MM.JJJJ";
        }
        const validDate = dayjs(parsedDate).format("DD.MM.YYYY") === date;
        if (!validDate) {
            return "Kein gültiges Datum";
        }
        return parsedDate;
    }
};

/*
 * Returns 1 if the IBAN is valid
 * Returns FALSE if the IBAN's length is not as should be (for CY the IBAN Should be 28 chars long starting with CY )
 * Returns any other number (checksum) when the IBAN is invalid (check digits do not match)
 */
function isValidIBANNumber(input: string): string | null {
    const SEPA_EINZUG_LAND: { [key: string]: boolean } =
    {
        BE: true,
        BG: true,
        DK: true,
        DE: true,
        EE: true,
        FI: true,
        FR: true,
        GR: true,
        IE: true,
        IT: true,
        HR: true,
        LV: true,
        LT: true,
        LU: true,
        MT: true,
        NL: true,
        AT: true,
        PL: true,
        PT: true,
        RO: true,
        SE: true,
        SK: true,
        SI: true,
        ES: true,
        CZ: true,
        HU: true,
        CY: true,
    };
    const CODE_LENGTHS: { [key: string]: number } = {
        AD: 24,
        AE: 23,
        AT: 20,
        AZ: 28,
        BA: 20,
        BE: 16,
        BG: 22,
        BH: 22,
        BR: 29,
        CH: 21,
        CR: 21,
        CY: 28,
        CZ: 24,
        DE: 22,
        DK: 18,
        DO: 28,
        EE: 20,
        ES: 24,
        FI: 18,
        FO: 18,
        FR: 27,
        GB: 22,
        GI: 23,
        GL: 18,
        GR: 27,
        GT: 28,
        HR: 21,
        HU: 28,
        IE: 22,
        IL: 23,
        IS: 26,
        IT: 27,
        JO: 30,
        KW: 30,
        KZ: 20,
        LB: 28,
        LI: 21,
        LT: 20,
        LU: 20,
        LV: 21,
        MC: 27,
        MD: 24,
        ME: 22,
        MK: 19,
        MR: 27,
        MT: 31,
        MU: 30,
        NL: 18,
        NO: 15,
        PK: 24,
        PL: 28,
        PS: 29,
        PT: 25,
        QA: 29,
        RO: 24,
        RS: 22,
        SA: 24,
        SE: 24,
        SI: 19,
        SK: 24,
        SM: 27,
        TN: 24,
        TR: 26,
        AL: 28,
        BY: 28,
        EG: 29,
        GE: 22,
        IQ: 23,
        LC: 32,
        SC: 31,
        ST: 25,
        SV: 28,
        TL: 23,
        UA: 29,
        VA: 22,
        VG: 24,
        XK: 20,
    };
    const iban = String(input)
        .toUpperCase()
        .replace(/[^A-Z0-9]/g, ""); // keep only alphanumeric characters
    const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/); // match and capture (1) the country code, (2) the check digits, and (3) the rest
    // check syntax and length
    if (!code || !code[1]) {
        return "Keine gültige IBAN";
    }
    if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
        const length = CODE_LENGTHS[code[1]];
        if (!length) {
            return `Unbekannter Ländercode ${code[1]}`;
        }

        return `IBAN muss ${length} Zeichen lang sein`;
    }
    if (!SEPA_EINZUG_LAND[code[1]]) {
        return "Sie haben eine IBAN aus einem Land eingegeben in dem keine Lastschriften durchgeführt werden können.";
    }
    // rearrange country code and check digits, and convert chars to ints
    const digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, (letter) => {
        return (letter.toString().charCodeAt(0) - 55).toString();
    });
    // final check
    if (mod97(digits) !== 1) {
        return "Keine gültige IBAN";
    }
    return null;
}

function mod97(string: string): number {
    let checksum: number = parseInt(string.slice(0, 2));
    for (let offset = 2; offset < string.length; offset += 7) {
        const fragment = String(checksum) + string.substring(offset, offset + 7);
        checksum = parseInt(fragment, 10) % 97;
    }
    return checksum;
}

export type ValidateResultType = "inline" | "popup";

type ValidateResultBase = {
    type: ValidateResultType,
    text: string,
};

export type ValidateResultInline = ValidateResultBase & { type: "inline" };
export type ValidateResultPopup = ValidateResultBase & { type: "popup" };

export type ValidateResult = ValidateResultInline | ValidateResultPopup;

export function isValidateResultInline(result: ValidateResult | undefined): result is ValidateResultInline {
    return result?.type === "inline";
}

export function isValidateResultPopup(result: ValidateResult | undefined): result is ValidateResultPopup {
    return result?.type === "popup";
}

export const validate = (data: AnswerChoices, validations: Record<ComponentId, Optional<ComponentValidations>>) => {
    const errors: { [key: string]: ValidateResult } = {};
    Object.keys(validations).forEach((id: ComponentId) => {
        const currentInput = data[id];
        const validatonConfig = validations[id] as typeof validations[typeof id];
        for (const validationKey of Object.keys(validatonConfig)) {
            if (errors[id]) {
                return;
            }
            switch (validationKey) {
                case "required":
                    {
                        const validation = validatonConfig[validationKey];
                        if (!validation) continue;
                        if (
                            !currentInput ||
                            !currentInput.value ||
                            (isString(currentInput.value) &&
                                !currentInput.value.trim())
                        ) {
                            errors[id] = { type: "inline", text: "Dieses Feld wird benötigt." };
                            return;
                        }
                    }
                    break;
                case "isEmail":
                    {
                        const validation = validatonConfig[validationKey];
                        if (!validation) continue;
                        if (
                            !currentInput ||
                            !currentInput.value ||
                            typeof currentInput.value !== "string" ||
                            !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(
                                currentInput.value
                            )
                        ) {
                            errors[id] = { type: "inline", text: "Ups... Diese Email ist nicht gültig." };
                            return;
                        }
                    }
                    break;
                case "isDate":
                    {
                        const validation = validatonConfig[validationKey];
                        if (!validation) continue;
                        if (typeof currentInput?.value !== "string")
                            throw new UnexpectedValueError(currentInput);

                        const dateErrors = checkIsDate(currentInput.value);
                        if (isString(dateErrors)) {
                            errors[id] = { type: "inline", text: dateErrors };
                        }
                    }
                    break;
                case "isAge":
                    {
                        const validation = validatonConfig[validationKey];
                        if (!validation) continue;
                        if (typeof currentInput?.value !== "string")
                            throw new UnexpectedValueError(currentInput);

                        const ageErrors = checkIsAge(
                            currentInput.value,
                            validation
                        );
                        if (ageErrors) {
                            errors[id] = ageErrors;
                        }
                    }
                    break;
                case "under18Warning":
                    {
                        const validation = validatonConfig[validationKey];
                        if (!validation) continue;
                        if (typeof currentInput?.value !== "string")
                            throw new UnexpectedValueError(currentInput);

                        const ageErrors = checkUnder18Warning(currentInput.value);
                        if (ageErrors) {
                            errors[id] = ageErrors;
                        }
                    }
                    break;
                case "isIBAN":
                    {
                        const validation = validatonConfig[validationKey];
                        if (!validation) continue;
                        if (typeof currentInput?.value !== "string")
                            throw new UnexpectedValueError(currentInput);

                        const ibanErrors = isValidIBANNumber(currentInput.value);
                        if (ibanErrors) {
                            errors[id] = { type: "inline", text: ibanErrors };
                        }
                    }
                    break;
            }
        }
    });
    return errors;
};
